import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,Select,
  Unstable_Grid2 as Grid,
  Typography,Input,
  Alert,IconButton,
  Stack,Container, SvgIcon,indc, ListItem, ListItemButton, ListItemText, InputLabel, RadioGroup, FormControl, FormControlLabel, Radio, FormLabel, List, ListItemIcon, MenuItem, Autocomplete, NativeSelect
} from '@mui/material';
import {FiKey, FiLoader} from 'react-icons/fi'
import { styled } from '@mui/material/styles';
import OrderService from "../../sevice/request.service"
import Paper from '@mui/material/Paper';  
import Slider from "react-slick";
import SimpleSlider from './slider';
import bacImg from '../../theme/images/background.jpg'
 
import { Facebook, FavoriteBorder, Instagram, LinkedIn, Person, PestControl } from '@mui/icons-material';
import ProductCard from './ProductCard';
import HorizontalLinearStepper from './step';
import BasicDateCalendar from './boking';
import otra from '../../theme/images/otraico1.png'
import { colorsApp } from '../../theme/colors';
import { TopNavMaine } from '../../layouts/top-nav-main';
import MainTitle from '../../component/otratext';
import MainTitle2 from '../../component/otratext2';
import CommentIcon from '@mui/icons-material/Comment';
import FoterPage from '../../layouts/footerPahe'; 
import { useLocation, useNavigate} from 'react-router-dom'
import moment from 'moment';
import { CgCalculator } from "react-icons/cg";
import WorktimePage from '../../component/worktimepage';
import axios from 'axios';
import { LayoutMainpage } from '../../layouts/layoutmainpage';
import { Sheet } from 'react-modal-sheet';
import { v4 as uuidv4 } from 'uuid';
import { useUserAuth } from '../../context/UserAuthContext';
var qs = require('qs');

let userinfo="0554343"
let USERDATA
let  intervalId 

const OrderPage=(props)=>{
const[loading,setloding]=useState(true)
const[pikOrder,setPikOrder]=useState(false)
const[name,setname]=useState("")
const[name1,setname2]=useState("")
const[gestEmaile,setgestEmaile]=useState("")
const[paymentUrl,setpaymentUrl]=useState('')
const[paymentref,setpaymentref]=useState('')
const [showfrme,setshowfrme]=useState(false)
const [isOpen, setOpen] = useState(false);
const[userphone,setuserphone]=useState('')
const[disable,setdisable]=useState(false)
const[btndisable,setbtndisable]=useState(false)
const {user}=useUserAuth()
let navigate=useNavigate()
 const location =useLocation()

const LayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    maxHeight:'100%'
  //   [theme.breakpoints.up('lg')]: {
  //     paddingLeft: SIDE_NAV_WIDTH
  //   }
  }));
  
  const LayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
  });

  useEffect(()=>{
    if(btndisable){
      console.log("btn is not dis")
     }else{
      console.log("btn is dis")
     }
  },[btndisable])



useEffect(()=>{
       
  if(user){
    USERDATA={ 
    email:user.email===null?"":user.email,
    phone:user.phoneNumber===null?"":user.phoneNumber,
    userid:user.uid,
    accsesstoken:user.accessToken
}
if(USERDATA.email===""){
  userinfo=USERDATA.phone
  setuserphone(USERDATA.phone)
  console.log("tes nomber",userinfo)
}else{
  userinfo=USERDATA.email
  console.log("tes nomber",userinfo)
}
}  
},[])

 
  const submitOrder=async()=>{
   
    const total= (Number(order.price*0.15+Number(order.price)))
    const orderdata={...location.state.data,total}
    
    if(orderdata){
      await OrderService.addorder(orderdata).then((res)=>{
        // console.log("order detals",res)
        sendMSG(orderdata)
        setPikOrder(true)
         
      })
    }
   
    
    
  } 
  const sendMSG=(orderdata)=>{
    const{phone,tabelname,tabelId,gest,bokingdate,bokingstarttime}=orderdata
    var data = qs.stringify({
      "token": "q7tjlgnzrz4cmtsb",
      "to":phone,
      // "body":`طاوله ${tabelname} رقم ${tabelId} وعدد الاشخاص ${gest} سوف يتم مراجعة الطلب والرد بتاكيد الحجزمع تحيات بستان عطرة`
      "body":` تشكرك لكم حجزكم في بستان عطرة  ${moment(bokingdate).format("DD-MM-YY")} الساعة ${bokingstarttime} مساء  
      طاولة  ${tabelname}   رقم# ${tabelId} وعدد الاشخاص ${gest} 
      لتاكيد الحجز الرجاء التحويل الى حساب رقم SA4180000419608016079084
      باسم مقهى عطرة الجادة 
      وارسال ايصال التحويل`  
  });
  
  var config = {
      method: 'post',
      url: 'https://api.ultramsg.com/instance80963/messages/chat',
      headers: {  
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : data
    };
  const response1= axios(config)
  .then(function (response) {
    //console.log("Test api xxxx",JSON.stringify(response.data));
    //res.status(201).send(response.data)
  })
  .catch(function (error) {
    //console.log(error);
  });

  return response1

  }


const prepareTopaymet=()=>{
  if(name==="" || name1==="" || gestEmaile===""){
    return alert("الرجاد التاكد من بيانات الضيف")
  }else{
    setPikOrder(true)
  }
  
}
const mKpaymnet= async()=>{
  console.log("start from order page ")
  const calclutBooking=(Number(order.price*0.15+Number(order.price)))
  
  const options={
    "method": "create",
    "store": "CAFE ATRA ALJADE",
    "authkey": "GrK6^7gsFH#8pp4z",
    "framed": 0,
    "language":"AR",
    "ivp_applepay": "1",
    "order": {
      "cartid":uuidv4(),
      "test": "1",
      "amount":  calclutBooking,
      "currency": "SAR",
      "description": "booking in  main piknick",
      "trantype": "sale" 
    },
    "customer": {
      "ref": "211xx11",
      "email": gestEmaile,
      "name": {
        "forenames":name,
        "surname": name1
      },
      "address": {
        "line1": "Al-maddinah mnwarrah aminah",
        "city": "Maddinah",
        "country": "SA"
      },
      "phone":userphone
    },
    "return": {
      "authorised": "https://www.merchantdomain.com/successful/?Txnorder=TESTing_153677468",
      "declined": "https://www.merchantdomain.com/declined/?Txnorder=TESTing_153677468",
      "cancelled": "https://www.merchantdomain.com/cancelled/?Txnorder=TESTing_153677468"
    }
  

  
}

await axios.post("https://damp-fjord-48653-d6135638df44.herokuapp.com/makePaymment",options).then((responded)=>{
  // console.log("Test  erorr",responded.data.error.message  )
  console.log("r ====",responded.data.order.url)
  setpaymentUrl(responded.data.order.url)
  setpaymentref(responded.data.order.ref)
  paymenttStatuse(responded.data.order.ref)
  setbtndisable(true)
}).finally((res)=>{
  setOpen(true)
  setshowfrme(true)

})
 

}

const chikePayment=async(val)=>{
  console.log("start chike with ref ====",val)
  await axios.post("https://damp-fjord-48653-d6135638df44.herokuapp.com/chikpayment",{ref:val}).then((responded)=>{
    //console.log("r ====",responded)
    if (responded.data.order.status.code==3){
        deletTimeslots()
      clearInterval(intervalId)
      setOpen(false)
      setdisable(true)
      submitOrder()
      //setTimeHolder([])
      //console.log("ok ok ok",responded.data)
    }
    
  }).finally(()=>{
    console.log("rfrence  ====",paymentref)
  })
}

const paymenttStatuse=(val)=>{
   
    intervalId=setInterval(() => {
    //console.log("start chike payment ")
    chikePayment(val)
  }, 3000);
  
}



const deletTimeslots=async()=>{
  const timeHolder=order.slot
  //console.log("cards",timeHolder)
 //await axios.post("http://157.245.90.40:4000/firbasequery1",{data:{date:"2024-08-13",starttime:1}}).then((responded)=>{
   await axios.post("https://damp-fjord-48653-d6135638df44.herokuapp.com/deletslot2",{data:timeHolder}).then((responded)=>{
   //console.log("slot was remove"  )
})
}


const exitPage=()=>{
  clearInterval(intervalId)
  navigate("/")
}

 
const order=location.state.data
 
    return(
       <LayoutMainpage>
              <TopNavMaine/>
              <>
              <Box sx={{alignItems:'center',flexDirection:'column',backgroundColor:colorsApp.backgroundPage,display:'flex'}}>
                <MainTitle width={"336px"} margin={5}/>
                {/* <MainTitle2  width={"100%"} text={""}/> */}
                 
                 {!loading?
                  <Stack sx={{padding:4,mt:5,mb:5}}>
                    <Typography>plase waite....</Typography>
                    <FiLoader size={40} color='brown'/>
                  </Stack>:
                   
                    <Card variant="outlined" sx={{direction:'rtl'   ,width:"400px",height:"109px",backgroundColor:"transparent",display:'flex',borderRadius:"3px",lineHeight:2,flexDirection:'column',borderWidth:2,borderColor:"#007468" ,mt:2 ,padding:1 }} >
                      <Stack sx={{px:0}}>
                        <Typography sx={{fontFamily:'Cairo',fontSize:10}} color={colorsApp.otraColotText} >X</Typography>
                      </Stack>
                     
                        <Stack sx={{flexDirection:'row',width:"100%",backgroundColor:"transparent",justifyContent:"space-around",mt:1}}>
                          <Stack sx={{ width:"60%",alignItems:'flex-start'}}>
                            <Typography sx={{fontFamily:'Cairo',fontWeight:'400',fontSize:18,lineHeight:"20.71px", }} color={colorsApp.otraColotText} letterSpacing={1.2} >{order.tabelname}</Typography>
                          </Stack>
                          <Stack sx={{flexDirection:'row',justifyContent:'space-between',width:"40%",alignItems:'flex-end'}}>
                            <Typography sx={{fontFamily:'Cairo',fontWeight:'400',fontSize:18,lineHeight:"20.71px"}} color={colorsApp.otraColotText} letterSpacing={1.2} >{order.gest}اشخاص</Typography>
                            <Typography sx={{fontFamily:'Cairo',fontWeight:'400',fontSize:18,lineHeight:"20.71px"}} color={colorsApp.otraColotText} letterSpacing={1.2} >{order.price}ريال</Typography>
                          </Stack>
                        </Stack>

                        <Stack sx={{flexDirection:'row',width:"100%",backgroundColor:"transparent",mt:1}}>
                            <Stack sx={{flexDirection:'row',width:'60%',alignItems:'flex-start'}} >
                              <Typography sx={{fontFamily:'Cairo',fontWeight:'400',fontSize:18,lineHeight:"20.71px"}} color={colorsApp.otraColotText} >{moment(order.bokingdate).format("dddd")}</Typography>
                              <Typography sx={{fontFamily:'Cairo',fontWeight:'400',fontSize:18,lineHeight:"20.71px"}} color={colorsApp.otraColotText} letterSpacing={1.2} >{order.bokingdate}</Typography>
                            </Stack>
                            <Stack sx={{flexDirection:'row',width:'40%',alignItems:'flex-end'}}>
                              <Typography sx={{fontFamily:'Cairo',fontWeight:'400',fontSize:18,lineHeight:"20.71px"}} color={colorsApp.otraColotText} letterSpacing={1.2} >من {order.bokingstarttime}</Typography>
                              <Typography sx={{fontFamily:'Cairo',fontWeight:'400',fontSize:18,lineHeight:"20.71px"}} color={colorsApp.otraColotText} letterSpacing={1.2} mr={1}>الى {order.bokingendtime}</Typography>
                            </Stack>
                          </Stack>
                          
                          {/* <CardActions sx={{justifyContent:'space-between',backgroundColor:"GrayText"}}>
                            
                          </CardActions> */}
                          

                    </Card>
                  
                  }
                 {pikOrder?
                  <Stack width={"100%"} alignItems={'center'} spacing={2} mt={5}>
                    <Button disabled={btndisable} variant="contained" fullWidth size="large"  color='success' sx={{mt:1}}
                      onClick={()=>mKpaymnet()}>صفحة الدفع</Button>
                    <Button disabled={btndisable} variant="outlined" color="inherit" fullWidth size="large"  sx={{mt:1}}
                      onClick={()=> exitPage()}>الغاء</Button>
                  </Stack>:
                  <Stack sx={{width:'391px',mt:3,mr:2,ml:2}}>
                    <Stack sx={{width:'100%',mt:1,mb:1,flexDirection:'row',backgroundColor:'#B0A798',borderRadius:"5px", padding:2,justifyContent:'space-between'}}>
                      <Typography color={"black"} fontFamily={"Cairo"} fontSize={18} fontWeight={"400"} sx={{textAlign:'center',ml:3,mr:3,lineHeight:"20.71px"}}>SAR {order.price}</Typography>
                      <Typography color={"black"} fontFamily={"Cairo"} fontSize={18} fontWeight={"400"} sx={{textAlign:'center',ml:3,mr:3,lineHeight:"20.71px"}}>الحجز</Typography>
                      
                    </Stack>
                    <Stack sx={{width:'100%',mt:1,mb:1,flexDirection:'row',backgroundColor:'#B0A798',borderRadius:"5px", padding:2,justifyContent:'space-between'}} >
                      <Typography color={"black"} fontFamily={"Cairo"} fontSize={18} fontWeight={"400"} sx={{textAlign:'center',ml:3,mr:3,lineHeight:"20.71px"}}> SAR {order.price*0.15} </Typography>
                      <Typography color={"black"} fontFamily={"Cairo"} fontSize={18} fontWeight={"400"} sx={{textAlign:'center',ml:3,mr:3,lineHeight:"20.71px"}}>الضريبه</Typography>
                    </Stack>
                    <Stack sx={{width:'100%',mt:1,mb:1,flexDirection:'row',backgroundColor:'#B0A798',borderRadius:"5px", padding:2,justifyContent:'space-between'}} >
                      <Typography color={"black"} fontFamily={"Cairo"} fontSize={18} fontWeight={"400"} sx={{textAlign:'center',ml:3,mr:3,lineHeight:"20.71px"}}> SAR {Number(order.price*0.15+Number(order.price))}</Typography>
                      <Typography color={"black"} fontFamily={"Cairo"} fontSize={18} fontWeight={"400"} sx={{textAlign:'center',ml:3,mr:3,lineHeight:"20.71px"}}>المجموع</Typography>
                    </Stack>
                    
                    <Box sx={{ width: "100%", padding: 1, mt: 3 }}>
                    <Stack
                      alignItems="center"
                      component={'address'}
                      sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                      noValidate
                      bgcolor={'inherit'}
                      spacing={3}
                    >
                      <Typography variant="h5" color={colorsApp.otraColotText}>بيانات الحجز</Typography>
                    <Stack   flexDirection={'column'} component={'div'} dir='rtl' >
                      <Stack flexDirection={'row'}  justifyItems={'baseline'}>
                        <Input type="text" size="medium"  sx={{fontSize:33}}  required 
                                onChange={(e)=>setname(e.target.value)} placeholder='الاسم' autoFocus margin='1' fullWidth/>
                        <Input type="text" size="medium" sx={{fontSize:33}} required 
                                onChange={(e)=>setname2(e.target.value)} placeholder='الاسم الاخير'  margin='1' />
                      </Stack>
                      <Stack>
                         <Input type="email" size="medium" sx={{fontSize:33}} required 
                                onChange={(e)=>setgestEmaile(e.target.value)} placeholder='الايميل'  />
                      </Stack>
                      <Stack>
                          <Button
                          variant='contained'
                          size='large'
                          color='success'
                          sx={{ mt:3, color: colorsApp.otraMaintext }}
                          onClick={() =>prepareTopaymet()}
                        >
                          تاكيد معلومات الضيف
                        </Button>
                        
                      </Stack>
                      
                    </Stack>
                    
                  </Stack>
                </Box>
                     
                    
                  </Stack>}
                  {disable&&<Box sx={{width:'100%', padding:1,mt:3}}>
                    <Stack alignItems="center" marginTop={3} > 
                      <Alert variant="outlined" severity="success" sx={{alignItems:'center',alignContent:'center',fontSize:22}}>
                        لقد تم ارسال طلبك وسوف يتم اسال تفاصيل الحجز عن طريق الواتس 
                      </Alert>
                       <Button variant="contained" size="large" color='secondary' sx={{mt:2}} onClick={()=>navigate("/")}>الرجوع الى الصفحه الرئيسيه</Button>
                    </Stack>
                  </Box>}
              
              </Box>
              <Sheet isOpen={isOpen} onClose={() => setOpen(false)} 
        snapPoints={[600, 400, 100, 0]}
        initialSnap={1}
        onSnap={(snapIndex) =>
          console.log('> Current snap point index:', snapIndex)
        } >
        <Sheet.Container >
          <Sheet.Header />
          <Sheet.Content> 
          {showfrme&&<iframe src={paymentUrl}   width={"100%"} height={"360"}  min-width= {"600px"}  
                      title='child frame' frameborder="0" >
                      </iframe>
              }
            
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
              <WorktimePage/>
              </>
              <FoterPage/>
              </LayoutMainpage>
    )
}


export default OrderPage;