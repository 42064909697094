

 
import React from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Unstable_Grid2 as Grid,
    Typography,
    Alert,
    Stack,Container, CardActionArea, CardMedia, Paper, ToggleButtonGroup, ToggleButton
  } from '@mui/material';
import { colorsApp } from "../../theme/colors";
import {useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const SimpleSlider =(props)=> {
  let navigate=useNavigate()
  const [alignment, setAlignment] = React.useState('web');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
 
  const tiers = [
    {
      title:"احجز طاولتك الان",
      price: '100',
      url:"/productshow",
      img:"https://res.cloudinary.com/di6ghy1su/image/upload/v1707558854/slide/set2_pfjpj3.jpg",
      subheader: 'من شخصين الى 15 شحص',
      description: ['دكات عطره', 'طاولات مميزه', 'اطلاله مميزه', 'تقديم خدمات الضيافه'],
      buttonText: 'احجز الان',
      buttonColor:colorsApp.Button_progress,
      buttonVariant: 'contained',
      disabled:true,
      redirct:"component"
    },
    {
      title: 'احجز مجلس عطرة',
      //subheader: 'Most popular',
      price: '30',
      url:"/mainpicknek",
      img:"https://res.cloudinary.com/di6ghy1su/image/upload/v1707558854/slide/set1_frvtqq.jpg",
      subheader: 'مخصص للمناسبات الخاصه',
      description: [
        'افطار رمضاني مميز',
        'نقديم خدمات الطاوله',
        'وجبات متنوعه',
        'خدمه مميزه',
      ],
      disabled:true,
      buttonText: 'قريبا..',
      buttonColor:colorsApp.Button_progress,
      buttonVariant: 'outlined',
      redirct:"mainpicknek"
    },
    {
      title:"زيارة فروعنا لاخرى",
      price: '500',
      url:"/bransh",
      img:"https://res.cloudinary.com/di6ghy1su/image/upload/v1707558854/slide/set4_i9pvjw.jpg",
      subheader: "نعرف على فروع عطرة الاخرى",
      description: [
        'تسع لعدة اشخاص',
        'تقديم خدمة الضيافه',
        'اجواء رائعه',
        'عشاء مميز',
      ],
      disabled:true,
      buttonText: 'احجز فطور رمضان',
      buttonVariant: 'outlined',
      buttonColor:colorsApp.Button_progress,
      redirct:"bransh"
    },
    {
      title:  "متجر عطرة",
      price: '500',
      url:"/gest",
      img:"https://res.cloudinary.com/di6ghy1su/image/upload/v1707558854/slide/set4_i9pvjw.jpg",
      subheader: "-",
      description: [
        'تسع لعدة اشخاص',
        'تقديم خدمة الضيافه',
        'اجواء رائعه',
        'عشاء مميز',
      ],
      disabled:false,
      buttonText: 'احجز الان',
      buttonVariant: 'outlined',
      buttonColor:colorsApp.Button_progress,
      redirct:"component"
    },
  ];
  const Boxess=styled('div')(({ theme }) => ({
     
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
      width: 155,
      backgroundColor:'red',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection:'row'
    },
  }))

const redirecTopage=(item)=>{

  if (!item.disabled){
     console.log("go website page")
      // window.open(item.url, '_blank')
    }else if (props.userauth===false){
      console.log("go login page")
       return navigate("/login");
    }else{
      console.log("go inside page")
      navigate(item.url)
    }
  }
   
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-evenly",
        gap: "2%",
        listStyle: "none",
        "& > :not(style)": {
          m: 0,
          width: 155,
          height: 100,
          alignItems: "stretch",
        },
      }}
    >
      {tiers.map((item, indx) => {
        return (
          <ToggleButton
            key={indx}
            sx={{ borderColor: "transparent" }}
            onClick={() => redirecTopage(item)}
          >
            <Paper
              elevation={22}
              key={indx}
              
              sx={{
                 backgroundColor:(colorsApp.otraGreenColor),
                height: 84,
                width: 160,
              }}
            >
              <Stack justifyContent={"center"} padding={1}>
                <Typography
                  gutterBottom
                  
                  alignSelf={"center"}
                  sx={{
                    fontFamily: "Cairo",
                    fontStretch: "expanded",
                    fontWeight: "600",
                    fontSize: 15,
                    color: !item.disabled? colorsApp.red:colorsApp.otraMaintext,
                  }}
                >
                  {item.title}
                </Typography>
              </Stack>
              <Stack justifyContent={"center"}>
                <Typography
                  fontSize={8}
                  component={"div"}
                  alignSelf={"center"}
                  color={!item.disabled? colorsApp.red:colorsApp.Milky}
                >
                  {item.subheader}
                </Typography>
              </Stack>
            </Paper>
          </ToggleButton>
        );
      })}
    </Box>
  );
 
}

export default  SimpleSlider;


// <div className="slider-container"> 
//     <Slider  {...settings} > 
//       {tiers.map((item)=>{
//         return(
//           <Stack  ml={4}  >
//             <Card sx={{  margin:2 }} key={item.img}>
//       <CardActionArea>
//         <CardMedia
//           component="img"
//           height="140"
//           image={item.img}
//           alt="green iguana"
//         />
//         <CardContent >
//             <Stack justifyContent={'flex-start'}>
//                 <Typography gutterBottom variant="h5" alignSelf={'center'} component="div">
//                 {item.title}
//             </Typography>
//             </Stack>
//             <Stack justifyContent={'center'}>
//               {item.description.map((dis)=>{
//                 return(  <Typography  fontSize={12} component={'div'} alignSelf={'center'} color="text.secondary">
//                   {dis}
//                   </Typography>)
//                 })}
//             </Stack>
//             <Stack  mt={3} >
//                <Button  disabled={item.disabled} size="small" variant={item.buttonVariant} color="primary" onClick={()=>//console.log("start" )}>{item.buttonText}</Button>
//             </Stack>
//           </CardContent>
          
//       </CardActionArea>
      
//     </Card>
//     </Stack>
//         )
         
//       })}

//     </Slider>
//     </div>